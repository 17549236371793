import React from 'react'
import useStyles from './Questions.style'
import Slider from '@mui/material/Slider';


export default function Questions(
        {handleSliderChange, 
        handleButtonChange, 
        pickBtnText, 
        SingleQuestion, 
        getButtonStyle,
        handleDisableSlider,
        handleSeeOptions
        
        
        }
    ) {
    const classes = useStyles()
    
    return (
        <div className={classes.Questions_Div}>
            <div className={classes.DivUpper}>
                <div className={classes.DivHead}>
                    QUESTIONS
                </div>

                <div className={classes.QuestionsBody}>
                    {SingleQuestion()}
                </div>

                <div className={classes.WPMDiv}>
                    <div className={classes.TimerHeader}>
                        Words Per Minute
                    </div>

                    <div className={classes.TimerSubHeader}>
                        Select a WPM below before starting
                    </div>

                    <div className={classes.WPM_Bar}>
                        <Slider defaultValue={350} 
                                aria-label="DefaultSlider" 
                                valueLabelDisplay="auto"
                                min={100}
                                max={1000}
                                step={10} 
                                disabled={handleDisableSlider()}
                                onChange={handleSliderChange}
                                />
                    </div>
                </div>

                <div className={classes.TimerDiv} 
                     style={getButtonStyle()}
                     onClick={handleButtonChange}
                      >
                        {pickBtnText()} 
                </div>

                <div className={classes.SeeOption}
                     onClick = {handleSeeOptions}
                     >
                SEE OPTIONS
                </div>

                
            </div>

        </div>
    )
}


