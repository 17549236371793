import { useState, useEffect } from "react"

// Given a number, create a range array, return the shuffled array
const shuffle_range = (num_para) => {
    const a = Array.from({length: num_para}, (v, k) => k)
    let random_a = []
    let i = a.length
    for (a, i; i--; ) {
        var random = a.splice(Math.floor(Math.random() * (i + 1)), 1)[0];
        random_a.push(random)
    }
    return random_a
  }

// Given number of paragraphs, and correct answer (the paragraph index), return an array of 3 candidates 
export const get_candidates = (num_para, answer_para) => {
    let res = shuffle_range(num_para)
    res = res.filter(item => item!==answer_para).slice(0,2)
    res.push(answer_para)
    const final_res = res.sort()
    return final_res
}

// Randomly pick n items from an array
export const random_N_Items = (myArr, n) => {
    let results = []
    const shuffled_order = shuffle_range(myArr.length).slice(0,n)
    console.log(shuffled_order)
    shuffled_order.map(item => results.push(myArr[item]))
    return results
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      })
    
      useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          })
        }
    
        // Add event listener
        window.addEventListener("resize", handleResize)
    
        // Call handler right away so state gets updated with initial window size
        handleResize()
    
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize)
      }, []) // Empty array ensures that effect is only run on mount
    
      return windowSize

}