import React, {useState} from 'react'
import useStyles from './Text.style'
import {ReactComponent as Clock}  from '../Icons/Clock.svg'
// import ReplayIcon from '@mui/material/Icon';
import ReplayIcon from '@mui/icons-material/Replay';
import TextField from '@mui/material/TextField'
import { useWindowSize } from '../helper';

export default function Text({started, article, timesUp, HandleRetry, 
    HandleNextArticle, mode, customText, HandleCustomText, warning}) {
    const classes = useStyles()
    const size = useWindowSize()
    const width = size.width
    
    const insertSpan = (text) => {
        const res = text.split(' ').map((str, index) => 
            <span className='foo' key={index}>{str} </span>
        )
        return res
    }

    // Function to split text by line "\n"
    const NewlineText = ({text}) => {
        const br_text = text.split('\n').map((str, index) => {
            if (str.length > 1) {
                return ( <p key={index} >{insertSpan(str)} <br/> <br/> </p> )
            } 
        })
        const myText = br_text.filter(item => typeof(item) != "undefined")
        console.log('My text: ',  myText)
        return myText
      }
    
    
    const Copy_Paste_Text = () => {
        return (
            <div className={classes.Copy_Paste_Div}>
                <TextField  
                value={warning ? warning : customText}
                onChange = {HandleCustomText}
                multiline = {true}
                minRows = {width > 600 ? 22 : 10}
                fullWidth = {true}
                maxRows = {width > 600 ? 22 : 15}
                placeholder = {
                 "Copy & paste text, select how many words per minute you wish to read at, then click ‘START NOW’"
                }
                />
            </div>
        )
    }


    return (
        <div className={classes.TextDiv}>
            <div className={classes.DivHead}>
                <span>TEXT</span>
            </div>

            <div className={classes.TextContent_Div}>
                <div className={classes.ContentHeader}>
                    <h2>
                        {mode === 1 ? "Preloaded Passages" : "Customized Passages"}
                    </h2>
                    <h3>
                        {timesUp ? `Your time is up.` : `Words color would change as you read through the text to ensure you are reading at the correct speed.`}
                    </h3>
                </div>

                <div className={classes.TextContent}>
                    {(started && !timesUp) &&
                    <NewlineText text={article} />}

                    {(!started && !timesUp) &&
                        (
                            mode===1 ? 
                            <div className={classes.BeforeStartText}>
                                Select how many words per minute you wish to read at before clicking ‘START NOW’
                            </div> : Copy_Paste_Text()   
                        )
                    }

                    {timesUp && 
                        <div className={classes.TimesUp_Div}> 
                            <div className={classes.Clock}>
                                <Clock />
                            </div>

                            <div className={classes.TimesUp_Text}>
                                Your time is up, please answer the questions to the right
                            </div>

                            <div className={classes.Retry_Button}
                                 onClick={HandleRetry}
                            >
                                <span>RETRY</span> 
                                <ReplayIcon />
                            </div>
                            <div className={classes.Retry_Button_Bottom} style={{display: `${mode===1 ? '' : 'none'}`}}>
                            </div>

                            {mode === 1 ? 
                                <div className={classes.Next_Article}
                                     onClick={HandleNextArticle}
                                        >
                                    NEXT ARTICLE
                                </div> : ''
                            }

                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
