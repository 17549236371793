import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles (theme => ({
    TextDiv: {
        width: '60%',
        backgroundColor: '#ffffff',
        minHeight: '45rem',
        maxHeight: '90%',

        [theme.breakpoints.down("xs")]: {
            width: '100%',
            marginBottom: '2rem',
            minHeight: '15rem',

        },
    },

    DivHead: {
        backgroundColor: '#F9893D',
        textAlign: 'center',
        color: 'white',
        fontSize: '1.4rem',
        padding: '0.5rem 0',
        fontWeight: 'lighter',
    },

    TextContent_Div: {
        padding: '1rem 2rem',
    },

    ContentHeader: {
        marginBottom: '1rem',
        "& h2": {
            fontSize: '1.4rem',
            fontWeight: '100',
            marginBottom: '0.7rem',
        },

        "& h3": {
            fontSize: '1rem',
            opacity: '0.5',
            fontWeight: '100',
        }
    },

    TextContent: {
        wordSpacing: '0.25rem',
        lineHeight: '1.6rem',
        fontSize: '1.2rem',
        textAlign: 'justify',

    },

    BeforeStartText: {
        fontSize: '1rem',
        opacity: '0.5',
        fontWeight: '100',
        width: '66%',
        marginLeft: '17%',
        marginTop: '35%',

        [theme.breakpoints.down("xs")]: {
            marginTop: '2rem',
            width: '80%',
            marginLeft: '10%',
        },


    },

    TimesUp_Div: {
        marginTop: '25%',
        [theme.breakpoints.down("xs")]: {
            marginTop: '2rem',
            
        },
        
    },

    Clock: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
    },

    TimesUp_Text: {
        width: '60%',
        marginLeft: '20%',
        opacity: '0.5',
        textAlign: 'center',
        fontSize: '1rem',
        marginBottom: '1rem',
    }, 

    Retry_Button: {
        color: '#F9893D',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        marginBottom: '1rem',
        
        "& span": {
            marginRight: '0.5rem',
        },
    },

    Retry_Button_Bottom: {
        borderBottomStyle: 'inset',
        // height: '1rem',
        width: '50%',
        marginLeft: '25%',
    },

    Next_Article: {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        color: '#F9893D',
        paddingTop: '1rem',
    },

    Copy_Paste_Div: {
        fontSize: '1rem',
        fontWeight: '100',
        width: '100%',
    }
}))

export default useStyles