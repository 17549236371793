import { makeStyles } from "@material-ui/core/styles"
import "@fontsource/montserrat"; 

const useStyles = makeStyles (theme => ({
    App : {
        backgroundColor: '#F1FAFB',
        color: '#393d44',
        minHeight: '100vh',
        fontFamily: 'Montserrat',
        
    },

    beforeLanded: {
        paddingTop: '3rem',
        paddingBottom: '9rem',
    },

    beforeLanded_Inner: {
        position: 'relative',
        width: '86%',
        marginLeft: '7%',
        paddingTop: '2rem',
        height: '85%',
        
        backgroundColor: '#e6f5fb',
        opacity: '95%',
        borderRadius: '1rem',

        [theme.breakpoints.down("xs")]: {
            paddingBottom: '3rem',
        },
    },

    HrGlass: {
        display: 'flex',
        justifyContent: 'center',
    },

    LaptopBoy_Div: {
        position: 'absolute',
        bottom: '-18%',
        right: '5%',
    },

    Horizontal_Div: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },

    beforeLanded_Text: {
        marginTop: '-0.5rem',

        "& h1": {
            fontSize: '2.5rem',
            textAlign: 'center',
            fontWeight: '100',
            marginBottom: '1rem',
            display: 'block',
        },

        "& h2": {
            fontSize: '2rem',
            fontWeight: 'lighter',
            textAlign: 'center',
            display: 'block',
            opacity: '0.5',
        },

        [theme.breakpoints.down("xs")]: {
            paddingTop: '7rem',

            "& h1": {
                fontSize: '2rem',
            },
            "& h2": {
                fontSize: '1.8rem',
            },
        },
    },

    Start_Button: {
        marginTop: '2rem',
        cursor: 'pointer',
        backgroundColor: '#F9893D',
        textAlign: 'center',
        color: 'white',
        padding: '0.8rem 2rem',
        borderRadius: '2rem',
        width: '40%',
        wordSpacing: '0.3rem',

        [theme.breakpoints.down("xs")]: {
            width: '76%',
        },
    },

    afterLanded: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '95%',
        marginLeft: '2.5%',
        paddingTop: '5rem',

        [theme.breakpoints.down("xs")]: {
            width: '94%',
            marginLeft: '3%',
            display: 'block !important',
            paddingTop: '1rem',
        },

    },

    QuestionHeader: {
        paddingTop: '1rem',
        fontSize: '1.2rem',
        marginBottom: '1rem',

        "& span": {
            fontWeight: 'bold',
        }
    }, 

    BoxOption: {
        "& .MuiCheckbox-colorSecondary.Mui-checked": {
            color: '#F9893D',
        }
    },

    CorrectOption: {
        color: '#00E232',
        "& .MuiSvgIcon-root": {
            fill: '#00E232',
        }
    },

    IncorrectOption: {
        color: '#FF0F0F',
        "& .MuiSvgIcon-root": {
            fill: '#FF0F0F',
        }
    },

    SkipDiv: {
        fontSize: '1.1rem',
        color: '#F9893D',
        textAlign: 'end',
        marginTop: '2.5rem',
        cursor: 'pointer',
    },

    Text_Before_Timer: {
        textAlign: 'center',
        paddingTop: '5rem',
        opacity: '0.5',
        fontSize: '1.2rem',
        minHeight: '8rem',
    },

    OptionsPage: {
        width: "76%",
        paddingTop: '5rem',
        marginLeft: '12%',
    },

    Option_Div: {
        paddingBottom: '4rem',
        paddingTop: '2rem',

        "& h1": {
            fontSize: '2.5rem',
            textAlign: 'center',
            fontWeight: '100',
            marginBottom: '1rem',
            display: 'block',
            opacity: '0.85',
        },

        "& h2": {
            fontSize: '2rem',
            fontWeight: 'lighter',
            textAlign: 'center',
            display: 'block',
            opacity: '0.5', 
        },
        
        "&:nth-child(1)": {
            borderBottomStyle: 'inset',
        },

        [theme.breakpoints.down("xs")]: {
            "&:nth-child(1)": {
                paddingTop: '0rem',
            },
            paddingBottom: '2rem',
            "& h1": {
                fontSize: '2rem',
            },
            "& h2": {
                fontSize: '1.6rem',
            },
        },
    },

    Start_Button_WithOption: {
        marginTop: '2rem',
        cursor: 'pointer',
        backgroundColor: '#F9893D',
        textAlign: 'center',
        color: 'white',
        padding: '0.8rem 2rem',
        borderRadius: '2rem',
        width: '40%',
        marginLeft: '30%',
        wordSpacing: '0.3rem',

        [theme.breakpoints.down("xs")]: {
            width: '80%',
            marginLeft: '0%',
        },
    }

    

}))

export default useStyles