import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles (theme => ({
    Questions_Div: {
        width: '35%',

        [theme.breakpoints.down("xs")]: {
            width: '100%',
            marginBottom: '3rem',
            paddingBottom: '2rem',
        },

    },

    DivHead: {
        backgroundColor: '#F9893D',
        textAlign: 'center',
        color: 'white',
        fontSize: '1.4rem',
        padding: '0.5rem 0',
        fontWeight: 'lighter',
    },

    QuestionsBody: {
        background: 'white',
        height: 'fit-content', 
        padding: '1rem 2rem',
    },

    WPMDiv: {
        textAlign: 'center',
        backgroundColor: 'white',
        marginTop: '1.5rem',
        height: '10rem',
    },

    TimerHeader: {
        paddingTop: '1rem',
        fontSize: '1.2rem',
    },

    TimerSubHeader: {
        marginTop: '.3rem',
        opacity: '0.5',
        fontSize: '0.9rem',
    },

    WPM_Bar: {
        "& .css-187mznn-MuiSlider-root": {
            color: '#F9893D',
            width: '86%',
            marginTop: '1.3rem',
        }
    },

    TimerDiv: {
        cursor: 'pointer',
        textAlign: 'center',
        marginTop: '1.5rem',
        fontSize: '1.3rem',
        padding: '1rem 0',
        borderRadius: '0.8rem',
    },

    SeeOption: {
        display: 'flex',
        justifyContent: 'end',
        color: '#F9893D',
        marginTop: '1.5rem',
        letterSpacing: '0.1rem',
        cursor: 'pointer',
    }

    // BoxOption: {
    //     "& .MuiCheckbox-colorSecondary.Mui-checked": {
    //         color: '#F9893D',
    //     }
    // },

    // CorrectOption: {
    //     color: '#00E232',
    //     "& .MuiSvgIcon-root": {
    //         fill: '#00E232',
    //     }
    // },

    // IncorrectOption: {
    //     color: '#FF0F0F',
    //     "& .MuiSvgIcon-root": {
    //         fill: '#FF0F0F',
    //     }
    // },

    // SkipDiv: {
    //     fontSize: '1.1rem',
    //     color: '#F9893D',
    //     textAlign: 'end',
    //     marginTop: '2.5rem',
    //     cursor: 'pointer',
    // }


}))

export default useStyles