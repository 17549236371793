import React, {useState, useEffect} from 'react';
import './App.style'
import useStyles from './App.style';
import Questions from './Components/Questions/Questions';
import Text from './Components/Text/Text';
import cx from 'classnames'
import {Checkbox} from '@material-ui/core'
import data from './Components/Data/data.json'
import {get_candidates, random_N_Items, useWindowSize} from "./Components/helper"
import {ReactComponent as HourGlass}  from "./Components/Icons/HourGlass.svg"
import {ReactComponent as ClockGirl}  from "./Components/Icons/ClockGirl.svg"
import {ReactComponent as LaptopBoy}  from "./Components/Icons/LaptopBoy.svg"
import LeadGen_Modal from './Components/LeadGen_Modal/LeadGen_Modal';

// This app also comes with a function to decide when to show the subscribe popup of the parent website.
// The popup shows up only after the user has read and finished answering questions for twice

function App() {
  const classes = useStyles()
  const size = useWindowSize()
  const width = size.width
  const showPopupThreshold = 2
  const [playedTimesThresholdReached, setPlayedTimesThresholdReached] = useState(false)
  let myTimeout;

  const shuffle = () => {
    const a = Array.from({length: data.length}, (v, k) => k)
    let random_a = []
    let i = a.length
    for (a, i; i--; ) {
        var random = a.splice(Math.floor(Math.random() * (i + 1)), 1)[0];
        random_a.push(random)
    }
    return random_a
  }

  // 0: Not picked 1: Regular 2: DIY (Copy & Paste your own text)
  const [mode, setMode] = useState(0)
  const [customText, setCustomText] = useState('')
  const [customQuestions, setCustomQuestions] = useState([
    {
        "header": "DIY_1: Which Paragraph can ... be found in",
        "options": {
            "A": "Paragraph 1",
            "B": "Paragraph 2",
            "C": "Paragraph 3"
        }, 
        "correctAnswer": 0
    },

    {
        "header": "DIY_2: Which Paragraph can ... be found in",
        "options": {
            "A": "Paragraph 1",
            "B": "Paragraph 2",
            "C": "Paragraph 3"
        }, 
        "correctAnswer": 1
    },

    {
        "header": "DIY_3: Which Paragraph can ... be found in",
        "options": {
            "A": "Paragraph 1",
            "B": "Paragraph 2",
            "C": "Paragraph 3"
        }, 
        "correctAnswer": 2
    }
])
  const [warning, setWarning] = useState('')

  const [article, setArticle] = useState( data[0].article )
  const [questions, setQuestions] = useState(data[0].questions)
  const [nthArticle, setNthArticle] = useState(0)
  const [randomOrder, setRandomOrder] = useState(shuffle())
  
  const [landed, setLanded] = useState(false)
  const [started, setStarted] = useState(false)
  const [beganReading, setBeganReading] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(questions[0])
  const [wpm, setWpm] = useState(350)
  // nth question within an article
  const [nth, setNth] = useState(0)
  const [picked, setPicked] = useState(false)
  const [correct, setCorrect] = useState(-1)
  const [timesUp, setTimesUp] = useState(false)

  // Sending message to parent
  function postCrossDomainMessage(msg) {
    console.log('Delivery from UCAT Speed Reading Tool', msg)
    if (window && window.parent){
      window.parent.postMessage(msg, "*");
    }
  }

  // =========================================== Data Cleaning 🍄
  // Function to split text by line "\n"
  const Split_Text = (text) => {
    text = text.trim()
    return text.split("\n")
  }

  useEffect(() => {
    data.forEach((item, index)=> {
      const split = Split_Text(item.article)
      if (split.length < 3){
        console.log('Length: ', split.length, "\nIndex: ", index, "\n", split[0])
      }
    })
  }, [])

  // ============================================ End of Data Cleaning

  useEffect(() => {
    if (beganReading && !timesUp) {
      var postMsg = {"isToolWorking": true}; 
      postCrossDomainMessage(postMsg);
    }
    else {
      var postMsg = {"isToolWorking": false}; 
      postCrossDomainMessage(postMsg);
    }
  }, [beganReading, timesUp])


  useEffect(() => {
    if (article && started){
      Generate_CustomQuestions()
    }
  }, [article, started])

  useEffect(() => {
    if (started && customText) {
      Generate_CustomQuestions()
    }
  }, [started, customText])

  
  useEffect(() => {
    // You can come back to this version if you don't want to shuffle articles. 🤖
    // setArticle(data[nthArticle].article)
    // setQuestions(data[nthArticle].questions)
    // setCurrentQuestion(data[nthArticle].questions[nth])

    setArticle(data[randomOrder[nthArticle]].article)
    // setCurrentQuestion(mode===1 ? data[randomOrder[nthArticle]].questions[nth] : customQuestions[nth])
    setCurrentQuestion(mode===1 ? questions[nth] : customQuestions[nth])
  }, [nthArticle, nth, mode, customQuestions, questions]) 

  
  useEffect(() => {
    if (beganReading && !timesUp) {
      HandleTimer()
    }
  }, [beganReading, timesUp])

  useEffect(() => {
    setBeganReading(beganReading)
    handleDisableSlider()
  }, [beganReading])

  useEffect(async () => {
    // Sign of end of one round
    if (questions && nth===questions.length-1 && correct !== -1){
      // Update localStorage.playedTimes
      let i = localStorage.getItem('playedTimes')
      if (i) {
        i = parseInt(i)
        i = i+1
        localStorage.setItem('playedTimes', i)
      }
      else {
        localStorage.setItem('playedTimes', 1)
      }
    }

    // postMessage to parent when user has used the tool twice
    const played_times = await localStorage.getItem('playedTimes')
    if (played_times && parseInt(played_times) === showPopupThreshold){
      postCrossDomainMessage({"playedTimesThresholdReached": true})
      setPlayedTimesThresholdReached(true)
    }
  }, [nth, correct])
  
  // Generate 3 questions based on custom text. 
  // 1. Split each paragraph by sentence 
  // 2. Create a question for each paragraph
  // 3. Randomly pick 3 questions 
  const Generate_CustomQuestions = async () => {
    let myText = undefined
    if (mode===1) {
      myText = article
    }
    if (mode===2) {
      myText = customText
    }
    let paragraphs = myText.split('\n').filter(item => item.length > 0)

    let questions_bank = []
    
    // Storing split sentences for each paragraph
    let sentences_paras = {}
    paragraphs.map((item, index) => {
      let sentences = item.match( /[^\.!\?]+[\.!\?]+/g )
      sentences_paras[index] = sentences
    })

    // Create a question for each paragraph 
    // 1. Randomly pick a sentence from this paragraph
    // 2. Did sentenceA appear in Para1, Para2, Para 3? 

    for (const [key, value] of Object.entries(sentences_paras)) {
      const random_index = Math.floor(Math.random() * value.length)
      const picked_sentence = value[random_index]
      // Knowing the correct answer (which paragraph this sentence belongs to), pick another 2 candidates. 
      const candidates = get_candidates(paragraphs.length, parseInt(key))
      let correct_opt = -1
      await candidates.map((item, index) => {
        if (item===parseInt(key)){
          correct_opt = index
        }
      })

      let sample = {
        "header": `${picked_sentence}`,
        "options": {
          "A": `Paragraph ${candidates[0]+1}`,
          "B": `Paragraph ${candidates[1]+1}`,
          "C": `Paragraph ${candidates[2]+1}`
        }, 
        "correctAnswer": correct_opt
      }
      questions_bank.push(sample)
    }
    // Randomly choose 3 questions out
    const three_questions = random_N_Items(questions_bank, 3)
    
    if (mode===1) {
      setQuestions(three_questions)
    }
    if (mode===2) {
      setCustomQuestions(three_questions)
    }
  }
  
  const ClickStart = () => {
    setLanded(true)
  }
  const handleSliderChange = async () => {
    // Only available before hitting 'BEGIN READING' or timesup
    if (!beganReading || timesUp) {
      var element = await document.querySelector('[aria-label="DefaultSlider"]')
      setWpm(element.value)
    }
  }

  const getButtonStyle = () => {
    if (started) {
        return (
            {backgroundColor: '#F9893D', color: 'white'}
        )
    }
    return (
        {backgroundColor: '#FFEFDE', color: '#F9893D',}
    )
  }

  const changeColor = (timer) => {
    var elemArray = document.getElementsByClassName('foo')
  
    const interval = timer/elemArray.length
    for (let i = 0; i < elemArray.length; i++) {
      setTimeout(() => {
        if (elemArray[i]) {
          elemArray[i].style.color = "#f99144";
        }   
      }, i * interval * 1000)
    } 
  }

  const HandleTimer = () => {
    const text = mode===1 ? article : customText
    const timerInSec = ((text.split(' ') .length / wpm) * 60 ).toFixed(0) 
    console.log('timerInSec: ', timerInSec, '\nwpm: ', wpm)
    changeColor(timerInSec)
    myTimeout = setTimeout(()=> setTimesUp(true), timerInSec * 1000 )

  }
  // 1. At least 3 paragraphs 2. Word count > 600 
  const Validate_Input_Text = async () => {
    let word_count = 0
    const paras = customText.split('\n').filter(item => item.length > 0)
    await paras.map(item => word_count += item.split(" ").length)

    if (paras.length < 3) {
      setWarning('Input text should include minimum of 3 paragraphs')
    }
    else if (word_count<100) {
      setWarning('Input text should include at least 100 words')
    }
    else {
      setStarted(true)
    }
  }

  
  const handleButtonChange = () => {
    if (!started) {
      if (mode===1) {
        setStarted(true)
      }
      // Checking input text content 
      if (mode===2) {
        Validate_Input_Text()
      }
    }
    else if (!beganReading) {
      setBeganReading(true)
      
      
      // In mobile view, move to top 
      if (width<=600){
        window.addEventListener("popstate", window.scrollTo(0, 0))  
      }
    }

    else {
        if (picked || picked === 0) {
            gradeMe(mode===1 ? questions[nth] : customQuestions[nth])
        }
    } 
  }

  

  const handleBoxClick = (index) => (event) => {
    // Only clickable before Grading
    if (correct === -1) {
        if (event.target.checked) {
            setPicked(index)
        }
        else {
            setPicked(false)
        }
    }
  }

  const handleBoxChecked = (index, question)  => {
    // Before Grading
    if (correct === -1) {
        return picked === index
    }
    else {
        return index === question.correctAnswer
    }
}
  
  const handleNext = () => {
    if (nth !== questions.length - 1){
      if((nth + 1) === questions.length ) {
        setNth(0)
      } 
      else {
          setNth(nth+1)
      }
      // Reset Grading
      setCorrect(-1)
      setPicked(false)
    }

  }

  const pickBtnText = () => {
    if (started) {
      if (!beganReading) {
        return "BEGIN READING"
      }
      else {
        if (correct === -1){
          return "SUBMIT ANSWER"
        }

        if (correct) {
            return 'CORRECT'
        }

        if (!correct) {
            return 'INCORRECT'
        }
      }  
    }
    else {
        return "START NOW"
    }
  }

  const gradeMe = (question) => {
    console.log('Grading...')
    if (picked === question.correctAnswer){
        console.log('正确！')
        // This item turns green 
        setCorrect(true)
    }
    else {
        console.log('错误！')
        setCorrect(false)
    }
  }

  const SingleQuestion = () => {
    return (
        <>    
            <div className={classes.Text_Before_Timer} 
                 style={{display: `${timesUp ? 'none' : '' }`}}>Questions will appear once the timer has stopped 
            </div>

            {currentQuestion && 
            <div style={{display: `${timesUp ? '' : 'none'}`}}>
              <div className={classes.QuestionHeader}>
                <span>{currentQuestion.header} </span>can be found in
              </div>
              {Object.values(currentQuestion['options']).map((item, index) => {
                  return (
                      <div key={index} 
                              className={cx(classes.BoxOption, pickItemStyle(index, currentQuestion))}
                              >
                          <Checkbox  
                              checked = {handleBoxChecked(index, currentQuestion)}
                              onClick = {handleBoxClick(index)}
                              />
                          <span style={{opacity: '0.8'}}>{item}</span>
                      </div>
                  )
              })}

              <div className={classes.SkipDiv}
                  onClick={handleNext}
                  style={{display: `${(questions.length > 1 || customQuestions.length > 1) && nth !== 2 ? '' : 'none'}`}}
                  >NEXT
              </div>
            </div> 
        }
  
        </>
    )
  }

  const pickItemStyle = (index, question) => {
    // Already graded
    if ( correct !== -1) {
        if (correct && index===picked) {
            return classes.CorrectOption
        }
        if (!correct && index===picked) {
            return classes.IncorrectOption
        }
        if (!correct && index===question.correctAnswer) {
            return classes.CorrectOption
        }
    }
  }

  const HandleRetry = async () => {
    setStarted(false)
    setBeganReading(false)
    setTimesUp(false)
    setNth(0)
    setPicked(false)
    setCorrect(-1)
  }

  const handleDisableSlider = () => {
    // After beganReading, disable the slider
    if (beganReading) {
      return true
    }
    else {
      return false
    }
  }

  const HandleNextArticle = () => {
    // How to make it random ? 🦊
    if((nthArticle + 1) === data.length ) {
      setNthArticle(0)
    } 
    else {
        setNthArticle(nthArticle+1)
    }
    // Reset 
    setStarted(false)
    setBeganReading(false)
    setCurrentQuestion(questions[0])
    setNth(0) 
    setPicked(false)
    setCorrect(-1)
    setTimesUp(false)
  }

  const LandingPage = () => {
    return (
      <div className={classes.beforeLanded}>
        <div className={classes.beforeLanded_Inner}>
          <div className={classes.HrGlass}>
            <HourGlass width={250} />
          </div>

          <div className={classes.Horizontal_Div}>
            {width>=600 && <ClockGirl />}
            
            <div className={classes.beforeLanded_Text}>
              <h1>Verbal Reasoning</h1>
              <h2>UCAT Speed Reading Trainer</h2>  

              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className={classes.Start_Button} onClick={ClickStart} >START NOW</div>  
              </div>
            </div>

            {/* Position placeholder */}
            {width >=600 &&
                <LaptopBoy style={{visibility: 'hidden'}}  />
            }

          </div>
          {width>=600 && 
            <div className={classes.LaptopBoy_Div} >
              <LaptopBoy />
            </div>
          }
        </div>
      </div>
    )
  }

  const ClickStart_Option = (myMode) => () => {
    setMode(myMode)
  }

  const OptionsPage = () => {
    return (
      <div className={classes.OptionsPage}>
        <div className={classes.Option_Div}>
          <h1>Select Option A</h1>
          <h2>Use our preloaded passages that tutors have trialed and verified as matching length and difficulty of the UCAT</h2>
          <div className={classes.Start_Button_WithOption} onClick={ClickStart_Option(1)} >START NOW</div>  
        </div>

        <div className={classes.Option_Div}>
          <h1>Select Option B</h1>
          <h2>Paste in your own passages within this option to practice reading styles and lengths you need to work on for the UCAT</h2>
          <div className={classes.Start_Button_WithOption} onClick={ClickStart_Option(2)} >START NOW</div>  
        </div>
        
      </div>
    )
  }

  const HandleCustomText = (event) => {
    if (warning) {
      setWarning('')
      event.target.value = ''
    }
    setCustomText(event.target.value)
  }

  const handleSeeOptions = () => {
    console.log('Going back to options page...')
    // lock it if it's reading 🐞
    // Reset everything!! 💨
    if (myTimeout){
      clearTimeout(myTimeout)
    }

    setMode(0)
    setCustomText('')
    setCustomQuestions([
      {
          "header": "DIY_1: Which Paragraph can ... be found in",
          "options": {
              "A": "Paragraph 1",
              "B": "Paragraph 2",
              "C": "Paragraph 3"
          }, 
          "correctAnswer": 0
      },

      {
          "header": "DIY_2: Which Paragraph can ... be found in",
          "options": {
              "A": "Paragraph 1",
              "B": "Paragraph 2",
              "C": "Paragraph 3"
          }, 
          "correctAnswer": 1
      },

      {
          "header": "DIY_3: Which Paragraph can ... be found in",
          "options": {
              "A": "Paragraph 1",
              "B": "Paragraph 2",
              "C": "Paragraph 3"
          }, 
          "correctAnswer": 2
      }
    ])
    setWarning('')
    setArticle(data[0].article)
    setQuestions(data[0].questions)
    setNthArticle(0)
    setRandomOrder(shuffle())
    setStarted(false)
    setBeganReading(false)
    setCurrentQuestion(questions[0])
    setWpm(350)
    setNth(0)
    setPicked(false)
    setCorrect(-1)
    setTimesUp(false)
  }


  const AfterLanded = () => {
    return (
      <div className={classes.afterLanded}>
        <Text started={started}
              article={mode===1 ? article : customText}
              timesUp={timesUp}
              mode={mode}
              HandleRetry={HandleRetry}
              HandleNextArticle={HandleNextArticle}
              HandleCustomText={HandleCustomText}
              customText={customText}
              warning={warning}
          />

        <Questions
          handleSliderChange={handleSliderChange}
          handleButtonChange={handleButtonChange}
          pickBtnText={pickBtnText}
          SingleQuestion={SingleQuestion}
          getButtonStyle={getButtonStyle}
          handleDisableSlider={handleDisableSlider}
          handleSeeOptions={handleSeeOptions}
          />
      </div>
    )
  }


  return (
    <div className={classes.App}>
      {playedTimesThresholdReached && <LeadGen_Modal /> }
      {/* <LeadGen_Modal /> */}
      {!landed && LandingPage()}
      {landed && mode === 0 && OptionsPage()}
      {landed && mode !== 0 && AfterLanded()}

    </div>
  );
}

export default App;
